<template>
  <v-container class="tips-bg-glass-effect">
    <v-row v-if="statusLoad" class="pt-5" style="margin-left: 5px;">
      <v-col cols="12">
        <backArrow/>
      </v-col>
      <v-col cols="12">
        <p style="margin: 0; font-size: 18px" class="main-strong-text">
          Dicas
        </p>

        <p class="generic-title">Para ter um dia melhor</p>
      </v-col>
    </v-row>
    <v-row v-if="statusLoad" style="margin-left: 5px;" v-for="(tip, index) in tips" :key="index">
      <v-col cols="12" ontent="center" justify="center">
        <p style="font-size: 20px" class="generic-text">{{ tip[0].category }}</p>
      </v-col>
      <v-col cols="12" class="mb-5">
        <v-layout class="scrolling-wrapper" align-start>
          <v-card
              style="display: inline-block"
              v-for="(tipItem, itemIndex) in tip"
              :key="itemIndex"
              class="mx-auto mr-7"
              max-width="220"
              @click="onClickTip(tipItem)"
          >
            <v-img
                class="white--text align-end"
                height="320px"
                :src="`data:image;base64,${tipItem.media.contents}`"
            >
              <div class="card-overlay">
                <div class="card-overlay-content">
                  <v-card-title style="">
                    {{ stringLimit(tipItem.internal_description ? tipItem.internal_description : '', 17) }}
                  </v-card-title>
                  <p style="font-size: 16px; padding: 16px; padding-top: 0; margin-bottom: 0" >
                    {{ stringLimit(tipItem.contents ? tipItem.contents : '', 60) }}
                  </p>
                </div>
              </div>
            </v-img>
          </v-card>
        </v-layout>
      </v-col>
    </v-row>
    <v-row v-if="statusLoad"></v-row>
  </v-container>
</template>

<script>
import backArrow from '../../components/covid/back-arrow';
import SocialNetworkService from '@/services/socialnetwork/SocialNetworkService'
import { mapMutations } from "vuex";
export default {
  name: "TipsMenu",
  components: { backArrow },
  data() {
    return {
      statusLoad: false,
      tips: []
    }
  },
  methods: {
    ...mapMutations(["loading", "showmsg"]),
    onClickTip (tipItem){
      if(tipItem.children && tipItem.children.length > 0) {
        this.$router.push({name: 'tip-detail', params: {tipsChildren: tipItem.children, id: tipItem.id}})
      }else{
        this.showmsg({
          text: "Não existem detalhes para esta dica.",
          type: "warning"
        });
      }
    },
    stringLimit (string = '', maxLength = 10) {
      if (string.length && string.length > maxLength) {
        return string.substring(0, maxLength) + '...'
      } else {
        return string
      }
    },
    groupBy(key) {
      return function group(array) {
        return array.reduce((acc, obj) => {
          const property = obj[key];
          acc[property] = acc[property] || [];
          acc[property].push(obj);
          return acc;
        }, {});
      };
    },
    orderTips(){
      this.tips.forEach((tip)=>{
        tip.sort((a, b) => (a.feed_order > b.feed_order) ? 1 : -1)
      })
    },
    async getTips() {
      await this._socialNetworkService.FindTips()
          .then((response) => {

            this.tips = response.data
            const tipsGroupBy = this.groupBy('category');
            this.tips = tipsGroupBy(this.tips);
            this.tips = Object.values(this.tips).reverse();
            this.orderTips();

          }).catch((error) => {
            this.showmsg({
              text: "Não foi possível listar as dicas.",
              type: "error"
            });
          })

    },
    async getMediaTips() {
      for(let i=0; i < this.tips.length; i++){
        for(let j=0; j < this.tips[i].length; j++){
          await this._socialNetworkService.FindMediaTips(this.tips[i][j].id)
              .then((response) => {
                this.tips[i][j].media = {contents: response.data[0].contents, type: response.data[0].type}
              }).catch(() => {
                this.showmsg({
                  text: "Não foi possível listar as medias do dicas.",
                  type: "error"
                });
              })
        }
      }
    },
  },
  async mounted() {
    this.loading(true);
    this._socialNetworkService = new SocialNetworkService();
    await this.getTips();
    await this.getMediaTips();
    this.loading(false);
    this.statusLoad = true
  }
}
</script>

<style scoped>
.tips-bg-glass-effect {
  height: 100% !important;
  background: rgba(255, 255, 255, .4);
  mix-blend-mode: normal;
  backdrop-filter: blur(53px);
  padding: 10px;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.card-overlay {
  position: relative;
  background: rgba(0, 0, 0, .4);
  height: 320px;
  background: linear-gradient(180deg, rgba(0,0,0,0) 40%, rgba(0,0,0,0.3) 59%, rgba(0,0,0,0.7) 85%);
}
.card-overlay .card-overlay-content {
  position: absolute;
  bottom: 0;
  left: 0;
}

</style>
